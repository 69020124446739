.src-components-ConfirmationMessage-styles__root--1JmuS {
  position: absolute;
  width: 100%;
  max-width: 600px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center; }
  .src-components-ConfirmationMessage-styles__root--1JmuS .button,
  .src-components-ConfirmationMessage-styles__root--1JmuS .button-outline {
    margin-top: 20px; }

.src-components-ConfirmationMessage-styles__logo--25hxG {
  width: 60px;
  height: 60px;
  display: block; }

.src-components-ConfirmationMessage-styles__message--oigrp {
  font-size: 16px;
  font-weight: 700;
  padding: 30px;
  text-align: center; }

@media (min-width: 992px) {
  .src-components-ConfirmationMessage-styles__root--1JmuS {
    flex-direction: row; }
  .src-components-ConfirmationMessage-styles__logo--25hxG {
    margin-right: 60px; }
  .src-components-ConfirmationMessage-styles__message--oigrp {
    padding: 0;
    text-align: left; } }
